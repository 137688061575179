<template>
  <section class="box-height">
    <h3 class="titwarp">
      菜单权限管理
      <el-button
        class="subIco"
        style="float: right; margin-top: 15px"
        type="default"
        plain
        size="mini"
        @click="addNew"
      >
        <i class="el-icon-plus"></i> 添加顶部菜单
      </el-button>
    </h3>
    <div class="box-title">
      <el-tree
        :data="setTree"
        :props="defaultProps"
        default-expand-all
        node-key="id"
        ref="SlotMenuList"
        @node-contextmenu="rihgtClick"
        accordion
      >
        <span class="slot-t-node" slot-scope="{ node, data }">
          <span v-show="data.children && data.children.length >= 1">
            <i
              :class="{
                'fa fa-plus-square': !node.expanded,
                'fa fa-minus-square': node.expanded,
              }"
            />
            <span
              ><i
                :class="data.type == 1 ? 'fa fa-dot-circle-o' : 'fa fa-list'"
                style="margin-right: 5px"
              ></i
              >{{ node.label }}</span
            >
          </span>
          <span v-show="!data.children || data.children.length == 0">
            <i class style="margin-right: 10px"></i>
            <span
              ><i
                :class="data.type == 1 ? 'fa fa-dot-circle-o' : 'fa fa-list'"
                style="margin-right: 5px"
              ></i
              >{{ node.label }}</span
            >
          </span>
        </span>
      </el-tree>
    </div>

    <!--鼠标右键点击出现页面-->
    <div v-show="menuVisible">
      <el-menu
        id="rightClickMenu"
        class="el-menu-vertical"
        @select="handleRightSelect"
        active-text-color="#fff"
        text-color="#fff"
      >
        <el-menu-item index="1" class="menuItem">
          <span slot="title">添加子节点</span>
        </el-menu-item>
        <el-menu-item index="2" class="menuItem">
          <span slot="title">修改该节点</span>
        </el-menu-item>
        <el-menu-item index="3" class="menuItem">
          <span slot="title">删除该节点</span>
        </el-menu-item>
      </el-menu>
    </div>
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="createDate.type">
                <el-radio :label="0">页面菜单</el-radio>
                <el-radio :label="1">操作按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="createDate.name"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路径" prop="path">
              <el-input
                v-model="createDate.path"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="关键字" prop="authority">
              <el-input
                v-model="createDate.authority"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="当前层级" prop="level">
              {{ createDate.level }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="排序值" prop="sortOrder">
              <el-input
                v-model="createDate.sortOrder"
                style="width: 50px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否启用" prop="status">
              <el-radio-group v-model="createDate.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align: center">
            <el-form-item label-width="0" prop="">
              <el-button
                type="primary"
                size="mini"
                @click="save('createDate')"
                :loading="SaveLoading"
                >保存</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import {
  addMenuPermission,
  MenuPermissionList,
  getMenuPermission,
  deleteMenuPermission,
} from "../../api/api";
export default {
  data() {
    return {
      SaveLoading: false,
      titName: "",
      createDate: {
        id: "",
        authority: "", //key
        level: "", //层级
        name: "",
        parentId: null,
        path: "",
        sortOrder: null,
        status: 1,
        type: 0,
        url: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写名称",
          },
        ],
        authority: [
          {
            required: true,
            trigger: "blur",
            message: "请填写关键字",
          },
        ],
      },
      dialogVisible: false,
      isLoadingTree: true, //是否加载节点树
      setTree: [], //节点树数据
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuVisible: false,
      objectID: null,
    };
  },

  methods: {
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addNew() {
      this.titName = "新增";
      this.dialogVisible = true;
      this.createDate.id = "";
      this.createDate.parentId = 0;
      this.createDate.level = 1;
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.SaveLoading = true;
          addMenuPermission(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.resetForm("createDate");
              this.loadList();
            }
            this.SaveLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadList() {
      MenuPermissionList().then((res) => {
        if (res.success) {
          this.setTree = res.result;
        }
      });
    },
    handleRightSelect(key) {
      if (key == 1) {
        this.NodeAdd(this.NODE, this.DATA);
        this.menuVisible = false;
      } else if (key == 2) {
        this.NodeEdit(this.NODE, this.DATA);
        this.menuVisible = false;
      } else if (key == 3) {
        this.NodeDel(this.NODE, this.DATA);
        this.menuVisible = false;
      }
    },
    NodeBlur(n, d) {
      //输入框失焦
      if (n.isEdit) {
        this.$set(n, "isEdit", false);
      }
      this.$nextTick(() => {
        this.$refs["slotTreeInput" + d.id].$refs.input.focus();
      });
    },
    NodeEdit(n, d) {
      //编辑节点
      this.titName = "修改";
      this.dialogVisible = true;
      this.createDate.id = d.id;
      getMenuPermission({ id: d.id }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.createDate.authority = res.result.authority;
          this.createDate.level = res.result.level;
          this.createDate.parentId = res.result.parentId;
          this.createDate.path = res.result.path;
          this.createDate.sortOrder = res.result.sortOrder;
          this.createDate.status = res.result.status;
          this.createDate.type = res.result.type;
          this.createDate.version = res.result.version;
        }
      });
    },
    NodeDel(n, d) {
      //删除节点
      if (d.children && d.children.length !== 0) {
        this.$message.error("此节点有子级，不可删除！");
        return false;
      } else {
        //新增节点可直接删除，已存在的节点要二次确认
        //删除操作
        let DelFun = () => {
          deleteMenuPermission({ id: d.id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        };
        //二次确认
        let ConfirmFun = () => {
          this.$confirm("是否删除 " + d.name + "？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              DelFun();
            })
            .catch(() => {});
        };
        //判断是否是新增节点
        ConfirmFun();
      }
    },
    NodeAdd(n, d) {
      //新增节点
      //判断层级
      if (n.level > 3) {
        this.$message.error("最多只支持四级！");
        return false;
      }
      //新增数据
      this.titName = "新增子节点";
      this.dialogVisible = true;
      this.createDate.id = "";
      this.createDate.parentId = d.id;
      this.createDate.level = n.level;
    },
    rihgtClick(event, object, value, element) {
      if (this.objectID !== object.id) {
        this.objectID = object.id;
        this.menuVisible = true;
        this.DATA = object;
        this.NODE = value;
      } else {
        this.menuVisible = !this.menuVisible;
      }
      document.addEventListener("click", (e) => {
        this.menuVisible = false;
      });
      let menu = document.querySelector("#rightClickMenu");
      /* 菜单定位基于鼠标点击位置 */
      menu.style.left = event.pageX - 80 + "px";
      menu.style.top = event.pageY - 70 + "px";
      menu.style.position = "absolute"; // 为新创建的DIV指定绝对定位
      menu.style.width = 160 + "px";
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss" scoped>
.box-height {
  width: 900px;
  margin: 0 auto;
  position: relative;
}
.box-title {
  background-color: #fff;
  padding: 20px;
  /* height: calc(100% - 80px); */
}
.titwarp {
  font-size: 16px;
  color: #333333;
  background-color: #fff;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  margin: 0;
  overflow: hidden;
  /* border-bottom: 20px solid #f2f2f2 */
}

.titwarp .titwarpRight {
  float: right;
}
.subIco {
  margin: 10px;
  .el-icon-question {
    font-size: 20px;
    vertical-align: middle;
  }
}

/*************************标签鼠标右击页面样式******************************/
.el-menu-vertical {
  border: 3px solid rgb(84, 92, 100);
  border-radius: 10px;
  z-index: 100;
}
.el-menu-vertical i {
  color: #777777;
}
.menuItem {
  height: 40px;
  line-height: 40px;
  background-color: #545c64;
  font-size: 1.2rem;
}
.menuItem:hover {
  background-color: #409eff;
}
</style>
